import { configureStore } from "@reduxjs/toolkit";
import { reducers as candidateLoginReducer } from "../redux/slice/CandidateLoginSlice";
// import { reducers as companyDashboardReducer } from "../redux/slice/CompanyDashboardSlice";
// import { reducers as clientDashboardReducer } from "../redux/slice/ClientDashboardSlice";
// import { reducers as clientLoginReducer } from "../redux/slice/ClientLoginSlice";

const store = configureStore({
  reducer: {
    // loginuser: loginReducer,
    //candidate
    candidateLogin: candidateLoginReducer.candidateLogin,
    candidateSignature: candidateLoginReducer.candidateSignature,
    candidatePersonalInfo: candidateLoginReducer.candidatePersonalInfo,
    candidateEducationInfo: candidateLoginReducer.candidateEducationInfo,
    updateCandidateEducation: candidateLoginReducer.updateCandidateEducation,
    candidateEmploymentInfo: candidateLoginReducer.candidateEmploymentInfo,
    candidateEmploymentInfoUpdate:
      candidateLoginReducer.candidateEmploymentInfoUpdate,
    candidateSetPassword: candidateLoginReducer.candidateSetPassword,
    candidateVerification: candidateLoginReducer.candidateVerification,
    candidateResidentInfo: candidateLoginReducer.candidateResidentInfo,
    candidateIdCheck: candidateLoginReducer.candidateIdCheck,
    forgotPassword: candidateLoginReducer.forgotPassword,
    setNewPassword: candidateLoginReducer.setNewPassword,
    allCandidateDetails: candidateLoginReducer.allCandidateDetails,
    addCommunicationPreference:
      candidateLoginReducer.addCommunicationPreference,
    candidateLoginStatusUpdate:
      candidateLoginReducer.candidateLoginStatusUpdate,
    candidateDetailsSubmission:
      candidateLoginReducer.candidateDetailsSubmission,
    candidateConcentVer: candidateLoginReducer.candidateConcentVer,
    updateCandidateAcceptStatus:
      candidateLoginReducer.updateCandidateAcceptStatus,
    // //company
    // companyDashboard: companyDashboardReducer.companyDashboard,
    // clientLicenseVerify: clientLoginReducer.clientLicenseVerify,
    // clientCreatePassword: clientLoginReducer.clientCreatePassword,
    // addClientInfo: clientLoginReducer.clientAddInfo,
    // addClientBusinessInfo: clientLoginReducer.addClientBusinessInfo,
    // addClientBusinessDetails: clientLoginReducer.addClientBusinessDetails,
    // companyPlans: companyDashboardReducer.companyPlans,
    // beginVerification: clientLoginReducer.beginVerification,
    // CancelbeginVerification: clientLoginReducer.CancelbeginVerification,
    // GetListOfChecks: companyDashboardReducer.GetListOfChecks,
    // GetClientInformation: companyDashboardReducer.GetClientInformation,
    // createCustomPlan: companyDashboardReducer.createCustomPlan,
    // updateClientInformation: companyDashboardReducer.updateClientInformation,
    // GetCandidateReports: companyDashboardReducer.GetCandidateReports,
    // clientLogout: clientLoginReducer.clientLogout,
    // clientFeedbackSubmit: companyDashboardReducer.clientFeedbackSubmit,
    // GetBillingDetails: companyDashboardReducer.GetBillingDetails,
    // DeletePlan: companyDashboardReducer.DeletePlan,
    // ClientCandidateSearch: clientDashboardReducer.ClientCandidateSearch,
    // GetStatsClient: clientDashboardReducer.GetStatsClient,
    // GetDailyStatsClient: clientDashboardReducer.GetDailyStatsClient,
    // GetAmountSpentClient: clientDashboardReducer.GetAmountSpentClient,
    // GetDashboardInteractionClient: clientDashboardReducer.GetDashboardInteractionClient,
    // tasks: taskReducers
  },
});

export default store;
