import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../api";
import { showLoading } from "../slice/loadingSlice";
import { baseUrl } from "../../constant/baseurl";

const FileUploadAsyncThunk = (method, type, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    // console.log("data:::",data)
    const apiRequest = {
      method: method,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Token " + localStorage.getItem("token"),
      },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      dispatch(showLoading.actions.toggleLoading(true));
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      dispatch(showLoading.actions.toggleLoading(false));

      if (response.statusFlag === true) {
        // console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      dispatch(showLoading.actions.toggleLoading(false));
      throw error;
    }
  });

export const candidateSignature = FileUploadAsyncThunk(
  "post",
  "candidateSignature",
  "Candidate_information/add_candidate_signature/"
);
export const candidateEducationInfo = FileUploadAsyncThunk(
  "post",
  "candidateEducationInfo",
  "Candidate_information/add_candidate_education/"
);
export const updateCandidateEducation = FileUploadAsyncThunk(
  "post",
  "updateCandidateEducation",
  "Candidate_information/update_candidate_education/"
);
export const candidateEmploymentInfo = FileUploadAsyncThunk(
  "post",
  "candidateEmploymentInfo",
  "Candidate_information/add_candidate_employmenthistory/"
);
export const candidateEmploymentInfoUpdate = FileUploadAsyncThunk(
  "post",
  "candidateEmploymentInfoUpdate",
  "Candidate_information/update_candidate_employmenthistory/"
);
export const candidateVerification = FileUploadAsyncThunk(
  "post",
  "candidateVerification",
  "CompanyCandidateRegister/add_personal_info/"
);
export const candidateIdCheck = FileUploadAsyncThunk(
  "post",
  "candidateIdCheck",
  "Candidate_information/documents_upload/"
);
// export const  = createAccountAsyncThunk('post', 'candidateEducationInfo', 'application/json', 'CandidateInformation/add_personalinfo/');
// export const  = createAccountAsyncThunk('post', 'candidateEmploymentInfo', 'application/json', 'CandidateInformation/add_personalinfo/');
