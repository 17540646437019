import { createSlice } from "@reduxjs/toolkit";
import {
  candidateConcentVer,
  candidateDetailsSubmission,
  candidateLogin,
  candidateSetPassword,
  forgotPassword,
  setNewPassword,
  updateCandidateAcceptStatus,
} from "../actions/CandidateLoginAction";
import {
  candidateEducationInfo,
  candidateEmploymentInfo,
  candidateEmploymentInfoUpdate,
  candidateIdCheck,
  candidateSignature,
  candidateVerification,
  updateCandidateEducation,
} from "../actions/CandidateSignAction";
import {
  addCommunicationPreference,
  candidateLoginStatusUpdate,
  candidatePersonalInfo,
  candidateResidentInfo,
  fetchAllCandidatedetails,
} from "../actions/CandidateDetailsAction";

const candidateLoginSlice = createSlice({
  name: "signupUser",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateLogin.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateLogin.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidatePersonalInfoSlice = createSlice({
  name: "candidateSignature",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidatePersonalInfo.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidatePersonalInfo.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidatePersonalInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateEducationInfoSlice = createSlice({
  name: "candidateEducationInfo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateEducationInfo.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateEducationInfo.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateEducationInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const updateCandidateEducationSlice = createSlice({
  name: "updateCandidateEducation",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCandidateEducation.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCandidateEducation.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(updateCandidateEducation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateEmploymentInfoSlice = createSlice({
  name: "candidateEmploymentInfo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateEmploymentInfo.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateEmploymentInfo.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateEmploymentInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateEmploymentInfoUpdateSlice = createSlice({
  name: "candidateEmploymentInfo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateEmploymentInfoUpdate.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateEmploymentInfoUpdate.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateEmploymentInfoUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateSignatureSlice = createSlice({
  name: "candidateSignature",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateSignature.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateSignature.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateSignature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateSetPasswordSlice = createSlice({
  name: "candidateSetPassword",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateSetPassword.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateSetPassword.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateSetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateVerificationSlice = createSlice({
  name: "candidateVerification",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateVerification.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateVerification.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateVerification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateResidentInfoSlice = createSlice({
  name: "candidateResidentInfo",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateResidentInfo.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateResidentInfo.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateResidentInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateIdCheckSlice = createSlice({
  name: "candidateIdCheck",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateIdCheck.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateIdCheck.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateIdCheck.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});
const setNewPasswordSlice = createSlice({
  name: "setNewPassword",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setNewPassword.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(setNewPassword.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(setNewPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const allCandidateDetailsSlice = createSlice({
  name: "fetchAllCandidatedetails",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCandidatedetails.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCandidatedetails.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(fetchAllCandidatedetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const addCommunicationPreferenceSlice = createSlice({
  name: "addCommunicationPreference",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCommunicationPreference.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(addCommunicationPreference.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(addCommunicationPreference.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});
const candidateLoginStatusUpdateSlice = createSlice({
  name: "candidateLoginStatusUpdate",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateLoginStatusUpdate.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateLoginStatusUpdate.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateLoginStatusUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateDetailsSubmissionSlice = createSlice({
  name: "candidateDetailsSubmission",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateDetailsSubmission.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateDetailsSubmission.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateDetailsSubmission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const candidateConcentVerSlice = createSlice({
  name: "candidateConcentVer",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(candidateConcentVer.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(candidateConcentVer.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(candidateConcentVer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});

const updateCandidateAcceptStatusSlice = createSlice({
  name: "updateCandidateAcceptStatus",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCandidateAcceptStatus.pending, (state) => {
        console.log("loaadingslice1");
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCandidateAcceptStatus.fulfilled, (state, action) => {
        console.log("loaading");
        state.loading = false;
        state.data = action.payload;
        // Call the success callback if provided
        if (typeof action.meta.arg.successFn === "function") {
          action.meta.arg.successFn(action.payload);
          console.log("action page", action.payload);
        }
      })
      .addCase(updateCandidateAcceptStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        // Call the failure callback if provided
        if (typeof action.meta.arg.failureFn === "function") {
          action.meta.arg.failureFn(action.error.message);
          console.log("action:::", action.error.message);
        }
      });
  },
});
export const reducers = {
  candidateLogin: candidateLoginSlice.reducer,
  candidateSignature: candidateSignatureSlice.reducer,
  candidatePersonalInfo: candidatePersonalInfoSlice.reducer,
  candidateEducationInfo: candidateEducationInfoSlice.reducer,
  updateCandidateEducation: updateCandidateEducationSlice.reducer,
  candidateEmploymentInfo: candidateEmploymentInfoSlice.reducer,
  candidateEmploymentInfoUpdate: candidateEmploymentInfoUpdateSlice.reducer,
  candidateSetPassword: candidateSetPasswordSlice.reducer,
  candidateVerification: candidateVerificationSlice.reducer,
  candidateResidentInfo: candidateResidentInfoSlice.reducer,
  candidateIdCheck: candidateIdCheckSlice.reducer,
  forgotPassword: forgotPasswordSlice.reducer,
  setNewPassword: setNewPasswordSlice.reducer,
  allCandidateDetails: allCandidateDetailsSlice.reducer,
  addCommunicationPreference: addCommunicationPreferenceSlice.reducer,
  candidateLoginStatusUpdate: candidateLoginStatusUpdateSlice.reducer,
  candidateDetailsSubmission: candidateDetailsSubmissionSlice.reducer,
  candidateConcentVer: candidateConcentVerSlice.reducer,
  updateCandidateAcceptStatus: updateCandidateAcceptStatusSlice.reducer,
};
